/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import coupleBikeRiding from '../../images/heroes/couple-bike-riding.jpg'
import purpleCheckMark from '../../images/icons/purple-check.svg'
import aetnaInsuranceCard from '../../images/illustrations/aetna-insurance-card.png'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import {
  Hero,
  LinkButton,
  SplitContent,
  Typography,
  VariableContent,
  useUser,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'

const Home = ({ data }) => {
  const { userLocation } = useUser()

  const brandyList = ['disclaimer-aet-stateplans-lp']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Aetna Medicare | Find the right plan for your individual coverage',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/state-plans/lp',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Aetna Medicare | Find the right plan for your individual coverage',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={coupleBikeRiding}
                  alt="elderly couple rides tandem bicycle and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="dark">
                    Living in {userLocation.state.fullName}? Aetna<sup>®</sup>{' '}
                    Medicare has plans that may be right for you.{' '}
                  </Typography>

                  <Typography variant="h4" color="dark">
                    Find a plan in {userLocation.city},{' '}
                    {userLocation.state.fullName}{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <SplitContent
          backgroundColor="dark"
          alignImageToBottom={false}
          image={
            <img src={aetnaInsuranceCard} alt="Aetna Insurance card mockup" />
          }
          mainContent={
            <>
              <Typography variant="h2" color="light">
                Not sure where to start?
              </Typography>
              <Typography variant="body" color="light">
                Aetna plans are available in your area. Aetna offers a variety
                of coverage options and price points.{' '}
              </Typography>
              <Typography variant="body" color="light">
                Find out what plan is right for you:
              </Typography>
              <LinkButton
                variant="feature"
                to="https://www.sunfirematrix.com/app/consumer/clearlink/18572496/"
                color="primary"
                className="margin-x-auto"
              >
                View Plan Details
              </LinkButton>
            </>
          }
        />

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                Medicare plans comparison chart
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <div className="comparison-table">
            <table className="table-main">
              <thead>
                <td>Features</td>
                <td>Medicare Part A</td>
                <td>Medicare Part B</td>
                <td>Medicare Advantage</td>
              </thead>
              <tbody>
                <tr>
                  <td>Inpatient Hospital Care</td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                  </td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                  </td>
                </tr>
                <tr>
                  <td>Hospice Care</td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Outpatient Medical Care</td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                  </td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                  </td>
                </tr>
                <tr>
                  <td>Coverage For Out-Of-Pocket Medical Expenses</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Emergency Care While Traveling Abroad</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                    <sup> *</sup>
                  </td>
                </tr>
                <tr>
                  <td>Routine Care, Such As Vision, Hearing & Dental</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                    <sup> *</sup>
                  </td>
                </tr>
                <tr>
                  <td>Fitness Memberships</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                    <sup> *</sup>
                  </td>
                </tr>
                <tr>
                  <td>Health & Wellness Programs</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                    <sup> *</sup>
                  </td>
                </tr>
                <tr>
                  <td>Out-of-Pocket Maximum Protection</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                    <sup> *</sup>
                  </td>
                </tr>
              </tbody>
            </table>
            <Typography variant="body" className="table-after">
              *Available only with certain Aetna Medicare health plans, not on
              all plans.
            </Typography>
          </div>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
